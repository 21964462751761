import { AdjustmentsVerticalIcon, CheckCircleIcon, BoltIcon, CalculatorIcon, CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, PencilIcon, PresentationChartLineIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Amc() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>

            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Actively Managed Certificate (AMC)</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none">

                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:gap-x-8">
                    <div>
                        <div className="mt-5 w-full max-w-7xl text-base leading-7 text-stone-800 ">
                            <p className="mt-3 text-xl leading-8">

                                The Actively Managed Certificate (AMC), a premier financial product traded by Altai Global, represents a sophisticated investment vehicle that encapsulates a diversified portfolio of underlying assets. This dynamic financial instrument is meticulously structured to adhere to a specified composition of assets or an index. Unlike static investment vehicles, the AMC is subject to change over time, offering:
                            </p>
                            <div className="flex mt-8 space-x-2">
                                <AdjustmentsVerticalIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Dynamic Structure & Flexibility.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                                The flexibility to adjust the portfolio's composition based on market conditions, trends, and the strategic vision of the external asset manager overseeing the AMC.
                            </p>
                            <div className="flex mt-8 space-x-2">
                                <BoltIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Speed to Market.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                                One of the hallmark advantages of the AMC is its remarkable speed to market. In an industry where timing and agility can significantly influence investment outcomes, the AMC stands out for is its ability to be rapidly deployed, enabling investors to capitalize on emerging opportunities without the typical delays associated with traditional investment vehicles.
                            </p>
                            <div className="flex mt-8 space-x-2">
                                <PencilIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Customization.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                                The AMC offers unparalleled flexibility, empowering asset managers to tailor the portfolio to meet specific investment goals and risk profiles. This is particularly advantageous for investors seeking customized solutions that align with their investment philosophy and market outlook.

                            </p>
                            <div className="flex mt-8 space-x-2">
                                <CalculatorIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Cost-Effectiveness.</strong>
                                </span>
                            </div>
                            <ul role="list" className="mt-5 max-w-7xl text-stone-800 space-y-3">
                                <li className="flex gap-x-3">
                                    <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                    <span className="text-justify">
                                        Minimize administrative and operational expenses
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                    <span className="text-justify">
                                        Offer a cost-effective alternative through streamlined processes and the elimination of unnecessary intermediaries.
                                    </span>
                                </li>
                                <li className="flex gap-x-3">
                                    <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                    <span className="text-justify">
                                        Benefit with lower fees and higher returns.

                                    </span>
                                </li>
                            </ul>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
