import { AdjustmentsHorizontalIcon, CheckCircleIcon, CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon, ShieldCheckIcon } from '@heroicons/react/20/solid'

export default function StructuredProducts() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Structured Products</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:gap-y-10">
                <div className="mt-5 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
                    <div>
                        <div className="max-w-7xl text-base leading-7 text-stone-800">
                            <p className="mt-3 text-xl leading-8">
                                Structured products offer a versatile investment solution, adapting seamlessly to a variety of market conditions to enhance the risk-return profile of a portfolio. They also offer effective management of equity positions. With the capability to provide Mark to Market simulations and back-testing, investors can explore and evaluate the potential of any investment strategy, ensuring informed decision-making and optimized investment outcomes.
                            </p>
                            <span className="leading-8 mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-0.5 font-semibold ">
                                Structuring
                            </span>
                            <p className="mt-3 pl-3 ">
                            <ul role="list" className="mt-5 max-w-7xl space-y-6 text-stone-800">
                                    <li className="flex gap-x-3">
                                        <AdjustmentsHorizontalIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" /> 
                                        <span>
                                        <strong className="font-semibold">Custom Structuring.</strong> We are able to structure products on demand and arrange a tailor-made issuer market tenders.
                                        </span>
                                    </li>

                                    <li className="flex gap-x-3">
                                        <GlobeAltIcon className="mt-1.5  h-5 w-5 flex-none text-rose-600" aria-hidden="true" /> 
                                        <span>
                                        <strong className="font-semibold">Direct Access.</strong> We provide direct relationships with all of the main Investment Banks that issue such products.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <ShieldCheckIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                        <span>
                                        <strong className="font-semibold">Strategic Oversight.</strong> We negotiate and check all the parameters of the investment strategies on your behalf and give you the full results of any tender.
                                        </span>
                                        
                                        </span>
                                    </li>
                                </ul>
                                
                               
                                
                            </p>
                            <span className="mt-8 text-lg leading-8 inline-flex items-center border-l-2 border-rose-500 px-2 py-0.5 font-semibold">
                                Strategic Positions
                            </span>
                            <p className="mt-3 pl-3">
                            Enhance the value of long-term core portfolio holdings:<br />
                               <ul role="list" className="mt-5 max-w-7xl grid grid-cols-2 text-stone-800">
                                    <li className="flex gap-x-3">
                                        <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Buy/Sell.</strong> Leverage sophisticated strategies that optimize buy and sell prices, enhancing the overall efficiency of trading activities. These strategies are designed to secure more favorable entry and exit points, maximizing potential gains from market movements.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3 ml-8">
                                        <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Cash extraction.</strong> Improve Loan-to-Value (LTV) ratios through strategic purchases and sales of options, enabling more efficient cash extraction from investments. This approach enhances liquidity and financial flexibility without compromising the underlying asset base.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3 mt-6">
                                        <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Diversification.</strong> Employ tools like total return swaps and the sale of options to achieve broader portfolio diversification. These instruments allow for exposure to a wider range of assets, spreading risk and enhancing potential returns across different market conditions.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3 mt-6 ml-8">
                                        <CheckCircleIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Hedging strategies.</strong> Implement hedging strategies to mitigate risk and protect investment values. By using derivatives and other financial instruments, investors can guard against market volatility and adverse movements, preserving capital and stabilizing returns
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3 mt-6">
                                        <CheckCircleIcon className="h-5 w-5 mt-1.5 flex-none text-rose-600" aria-hidden="true" />
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Yield enhancement strategies.</strong>Explore yield enhancement strategies that utilize options, swaps, and other derivatives to generate additional income. These strategies aim to boost the overall yield of the portfolio, capitalizing on market inefficiencies and volatility for higher returns.
                                        </span>
                                    </li>
                                </ul> 
                            </p>
                            <span className="leading-8 mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-0.5 font-semibold">
                                Client Platform
                            </span>
                            <p className="mt-3 pl-3">
                                We access a Structured Products management platform that allows you to effectively manage the risks inherent in these investments but also to generate dashboards and portfolio reports on demand. Price controls can be set to ensure proper monitoring of secondary market positions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
