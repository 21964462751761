import { AdjustmentsVerticalIcon, CheckCircleIcon, BoltIcon, CalculatorIcon, CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, PencilIcon, PresentationChartLineIcon, RectangleGroupIcon, ServerIcon, ShieldCheckIcon, BanknotesIcon, PresentationChartBarIcon } from '@heroicons/react/20/solid'

export default function FinancialEngineering() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>

            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Financial Engineering</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none">

                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:w-full lg:max-w-7xl lg:gap-x-8">
                    <div>
                        <div className="mt-5 w-full max-w-7xl text-base leading-7 text-stone-800 ">
                            <p className="mt-3 text-xl leading-8">

                            At Altai Global, our financial engineering services stand at the forefront of innovative finance, tailored to meet the diverse needs of our clients. Our expertise spans across three key areas:


                            </p>
                            <div className="flex mt-8 space-x-2">
                                <ShieldCheckIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Securitization.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                            We specialize in the art of packaging assets into marketable securities. This process involves pooling various financial assets and then selling them to investors as consolidated securities. By doing so, we unlock liquidity for asset holders, enabling them to free up capital and redistribute risk. Our approach not only enhances the attractiveness of these assets to a broader investor base but also facilitates improved market efficiency and access to capital.


                            </p>
                            <div className="flex mt-8 space-x-2">
                                <BanknotesIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Financing.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                            Our financing solutions are designed to provide our clients with flexible, secure funding options. We offer Lombard loans, allowing clients to borrow against their securities portfolios, providing them with liquidity while still retaining their investment positions. Additionally, we provide repo (repurchase agreement) solutions, offering short-term borrowing for dealers in government securities. These services are crafted to ensure that our clients have access to the funds they need, when they need them, under terms that best suit their financial strategies.


                            </p>
                            <div className="flex mt-8 space-x-2">
                                <PresentationChartBarIcon className="mt-1.5 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                                <span>
                                    <strong className="leading-8 text-lg">Capital Structuring.</strong>
                                </span>
                            </div>
                            <p className="mt-2">
                            Through innovative structures such as Trusts and Life Insurance solutions, along with bespoke, ad-hoc solutions tailored to specific client needs, we excel in capital structuring. Our team works closely with clients to understand their unique financial landscapes, designing structures that optimize tax implications, enhance asset protection, and align with their long-term strategic goals. Whether it’s navigating complex regulatory environments or crafting intricate financing structures, our goal is to provide our clients with the frameworks they need to succeed financially.

                            </p>
         
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
