import { EnvelopeIcon } from '@heroicons/react/20/solid'
import BorderTransition from './assets/border-transition.png'

export default function Offices() {
    return (
    <>
       <img src={BorderTransition} alt="" className=""/>

      <div className="bg-stone-50 pt-10 pb-20 border-b border-stone-200" id="contact">
        
        <div className="mx-auto max-w-7xl px-6 lg:px-20 ">
          <div className="mx-auto max-w-2xl lg:mx-0">
            <h2 className="text-3xl font-bold tracking-tight text-stone-900">Contact Us</h2>
            <p className="mt-4 text-lg leading-7 text-stone-600">
            We're here to assist you with any inquiries or support you may need. Our global offices are poised to provide you with the personalized attention and expertise you require. Reach out to us via email or contact any of our local offices directly.

           
            </p>
          </div>
          <div className="mx-auto mt-5 text-lg leading-7 text-stone-600 gap-x-2 inline-flex">
   
          <EnvelopeIcon className="h-7 w-6 text-stone-500"/> Email us at <a href="mailto:info@altai-global.com" className="underline text-rose-700">info@altai-global.com</a>
          </div>
          <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-8 text-base leading-7 sm:grid-cols-2 sm:gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-4">
            <div>
              <h3 className="border-l-2 border-rose-600 pl-6 font-semibold text-stone-900 text-lg">France</h3>
              <address className="border-l-2 border-stone-200 pl-6 pt-2 not-italic text-stone-600">
                <p>32 Rue de l’Hôtel des Postes</p>
                <p>06000, Nice</p>
                <p className="font-semibold">+33 4 9301 4502</p>
              </address>
            </div>
            <div>
              <h3 className="border-l-2 border-rose-600 pl-6 font-semibold text-stone-900 text-lg">Mauritius</h3>
              <address className="border-l-2 border-stone-200 pl-6 pt-2 not-italic text-stone-600">
                <p>7A Mayer Street</p>
                <p>Port Louis, Mauritius</p>
                <p className="font-semibold">+230 525 49 565</p>
              </address>
            </div>
            <div>
              <h3 className="border-l-2 border-rose-600 pl-6 font-semibold text-stone-900 text-lg">Dubai</h3>
              <address className="border-l-2 border-stone-200 pl-6 pt-2 not-italic text-stone-600">
                <p>Conrad Business, Center Office</p>
                <p>1602 Sheikh Zayed Road</p>
                <p className="font-semibold">+971 55 557 9969</p>
              </address>
            </div>
          </div>
        </div>
      </div>
    </>
    )
  }
  