import { ChevronRightIcon } from '@heroicons/react/20/solid'
import { useId } from 'react'
import Typewriter from 'typewriter-effect';

export default function Hero() {

  return (
    <div className="-z-20 relative isolate overflow-hidden border-b border-stone-300 ">
      {/* <svg
        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-stone-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
        aria-hidden="true"
      >
        <defs>
          <pattern
            id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
            width={200}
            height={200}
            x="50%"
            y={-1}
            patternUnits="userSpaceOnUse"
          >
            <path d="M.5 200V.5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
          <path
            d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
      </svg> */}
      <div
        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden bg-stone-50 blur-3xl lg:ml-24 xl:ml-48"
        aria-hidden="true"
      >
        <div
          className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#E11D48] to-[#E11D48] opacity-50"
          style={{
            clipPath:
              'polygon(93.39% 28.07%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 100% 100%, 97.2% 52.8%, 95% 29.03%)'
          }}
        />
      </div>
      {/* <BackgroundIllustration className="-z-20 absolute left-2/3 top-4 h-[1026px] w-[1026px] -translate-x-1/3 stroke-stone-800/70 [mask-image:linear-gradient(to_bottom,white_20%,transparent_75%)] sm:top-16 sm:-translate-x-1/2 lg:-top-16 lg:ml-12 xl:-top-14 xl:ml-0" /> */}
      {/* <img src={"https://images.unsplash.com/photo-1496307653780-42ee777d4833?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"} 
        className="left-60 opacity-20 absolute -z-20  t-0 blur-xs inset-0 h-full w-full object-cover brightness-150" />*/}
      {/*  saturate-50 */}
      <div className="mx-auto max-w-7xl px-6 lg:px-20 pb-24 pt-10 lg:flex">
        <div className="mx-auto max-w-4xl lg:mx-0 lg:max-w-4xl lg:flex-shrink-0 lg:pt-8">
          <h1 style={{ display: "inline-block", opacity: "1", filter: "blur(0px)", transform: "none" }} className="z-40 mt-10 text-4xl font-bold tracking-tight text-stone-700 sm:text-6xl">
            The Gateway to
          </h1>
          <h1 className="z-40 mt-3 text-4xl font-bold tracking-tight text-rose-600 sm:text-6xl"> <Typewriter
            // onInit={(typewriter) => {
            //     typewriter.typeString("Empowered Trading").clear().typeString("Exclusive Financial Markets").start();

            // }}
            options={{
              strings: ["Empowered Trading", "Exclusive Financial Markets", "Exceptional Market Opportunities", "Expertise, Innovation, Growth"],
              autoStart: true,
              loop: true,
            }}

          /></h1>
          <p className="mt-5 text-2xl leading-8 text-stone-700 font-medium italic">
            Your Trusted, Independent, Regulated Partner
          </p>
          <div className="mt-10 flex items-center gap-x-6">
            {/* <a
              href="#"
              className="bg-rose-600 px-4 py-3 text-md font-semibold text-white shadow-sm hover:bg-rose-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-rose-600"
            >
              Contact us
            </a> */}
            <a href="#" className="font-semibold text-rose-600">
              <span className="absolute inset-0" aria-hidden="true" />
              Learn more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <div className="content-center items-center flex flex-none flex-row flex-nowrap gap-0 justify-center overflow-hidden absolute -z-10 p-0 inset-0" >
            {Array.from({ length: 20 }, (_, index) => (
              <div className="flex-[1_0_0px] h-full relative w-px z-10">
              <div className="h-[405px] relative w-12" style={{ backdropFilter: "blur(24px)", backgroundColor: "rgba(217, 217, 217, 0.1)", mask: "linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.3) 56.0037%, rgb(0, 0, 0) 100%)", height: "100%", width: "100%", opacity: "1" }}></div>
            </div>
            ))}
        </div>
        <div className="mx-auto mt-16 flex max-w-2xl sm:mt-24 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
          <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
          </div>
        </div>
      </div>
      <div className="absolute -left-10 bottom-0 flex h-8 items-end overflow-hidden">
        <div className="flex -mb-px h-[2px] w-80 -scale-x-100">
          <div className="w-full flex-none blur-sm [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#E11D48_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div>
          <div className="-ml-[100%] w-full flex-none blur-[1px] [background-image:linear-gradient(90deg,rgba(56,189,248,0)_0%,#E11D48_32.29%,rgba(236,72,153,0.3)_67.19%,rgba(236,72,153,0)_100%)]"></div></div></div>

    </div>
  )
}



function BackgroundIllustration(props) {
  let id = useId()

  return (
    <div {...props}>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-slow"
      >
        <path
          d="M1025 513c0 282.77-229.23 512-512 512S1 795.77 1 513 230.23 1 513 1s512 229.23 512 512Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M513 1025C230.23 1025 1 795.77 1 513"
          stroke={`url(#${id}-gradient-1)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-1`}
            x1="1"
            y1="513"
            x2="1"
            y2="1025"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#f43f5e" />
            <stop offset="1" stopColor="#f43f5e" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
      <svg
        viewBox="0 0 1026 1026"
        fill="none"
        aria-hidden="true"
        className="absolute inset-0 h-full w-full animate-spin-reverse-slower"
      >
        <path
          d="M913 513c0 220.914-179.086 400-400 400S113 733.914 113 513s179.086-400 400-400 400 179.086 400 400Z"
          stroke="#D4D4D4"
          strokeOpacity="0.7"
        />
        <path
          d="M913 513c0 220.914-179.086 400-400 400"
          stroke={`url(#${id}-gradient-2)`}
          strokeLinecap="round"
        />
        <defs>
          <linearGradient
            id={`${id}-gradient-2`}
            x1="913"
            y1="513"
            x2="913"
            y2="913"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#f43f5e" />
            <stop offset="1" stopColor="#f43f5e" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  )
}