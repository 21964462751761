import { CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function FixedIncome() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Fixed Income</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:gap-y-10">

                <div className="mt-5 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
                    <div className="lg:pr-4">
                        <div className="max-w-7xl text-base leading-7 text-stone-800">
                            <p className="mt-3 text-xl leading-8">
                            At the forefront of financial innovation, Altai Global introduces a suite of solutions designed to revolutionize the fixed income market. These offerings are meticulously tailored to address the most pressing needs of modern investors, including unparalleled liquidity, efficient execution, and comprehensive data & analytics. By focusing on these key areas, Altai Global aims to empower investors with the tools and insights necessary to navigate the complexities of the fixed income landscape with confidence and strategic acumen.
                            </p>
                            <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-1 font-semibold text-stone-800">
                            Unparalleled Liquidity
                            </span>
                            <p className="pl-3 mt-3">
                            In the realm of fixed income, liquidity stands as a paramount concern for investors, directly influencing the ease with which they can enter or exit positions. Altai Global distinguishes itself in this regard by providing access to an expansive network of over 100 market counterparties and more than 50 institutional clients. This vast access ensures that investors can readily find buyers or sellers, significantly reducing the time and cost associated with trading. The breadth of this network supports a robust and dynamic market environment, facilitating smoother transactions and enhancing the overall liquidity of fixed income investments. This level of liquidity is especially crucial in times of market volatility, where rapid adjustments to positions can be pivotal to investment strategy and performance.
                            </p>
                            <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-1 font-semibold text-stone-800">
                            Efficient Execution
                            </span>
                            <p className="mt-3 pl-3">
                            Efficiency in the execution of trades is a critical aspect of fixed income investing, where even marginal differences in transaction timing and pricing can have significant impacts on returns. Altai Global leverages its deep knowledge and extensive experience to streamline the execution, reporting, and settlement of trades. This efficiency not only optimizes operational processes but also maximizes the financial outcomes for investors. By accessing Altai Global's resources, investors benefit from a seamless trading experience, where transactions are conducted swiftly and accurately. This efficiency is a testament to Altai Global's commitment to leveraging its expertise for the benefit of its clients, ensuring they can execute their investment strategies with precision and confidence.


                            </p>
                            <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-1 font-semibold text-stone-800">
                            Data & Analytics
                            </span>
                            <p className="mt-3 pl-3">
                            In today's data-driven investment landscape, having access to comprehensive data and analytics is indispensable for making informed decisions. Altai Global offers a suite of tailor-made reporting, portfolio analysis, and personalized bond lists that empower investors to deeply understand their fixed income investments. This access enables investors to monitor their portfolios' performance closely, assess risk exposure, and identify opportunities for optimization. The personalized bond lists, in particular, provide a curated selection of securities that align with the investor's specific criteria, preferences, and risk tolerance. This level of customization and insight allows investors to navigate the complexities of the fixed income market with greater clarity and confidence, leveraging data to drive strategic investment decisions.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
