import { CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function RegulatoryInformation() {
  return (
    <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
        <div className="w-full lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
          <div>
            <p className="text-base font-semibold leading-7 text-rose-600">Compliance Insights</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Regulatory Information</h1>
          </div>
        </div>
      </div>
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:gap-y-10">

        <div className="mt-5 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
          <div className="lg:pr-4">
            <div className="max-w-7xl text-base leading-7 text-stone-800">
              <p className="mt-3 text-xl leading-8">
                Altaï Global Ltd is duly licensed under the Global Business Licence (GBL) with the license number GB20026201, issued on the 25th of January, 2021. Additionally, we hold an Investment Dealer Licence for full services dealing (excluding underwriting) bearing the same license number GB20026201, granted by the Financial Services Commission of Mauritius (FSC) on the same date. These certifications underscore our commitment to compliance and excellence in the provision of global financial services.
              </p>
              <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-1 font-semibold text-stone-800">
                Details
              </span>
              <p className="pl-3 mt-3 font-semibold">Certificate of Incorporation N°177723 dd 22th of January 2021</p>
              <p className="pl-3 mt-2"><span className="font-semibold">Tax Account Number (TAN):</span> 27885787</p>
              <p className="pl-3 mt-2"><span className="font-semibold">Legal Entity Identifier (LEI):</span> 549300JH07MXRGRG6F31</p>
              <p className="pl-3 mt-2"><span className="font-semibold">Secretary Company:</span> 7A Mayer Street, Port Louis, Mauritius.</p>
              <p className="pl-3 mt-2"><span className="font-semibold">Secretary Company:</span> Accuvise Administrators Limited</p>
              <p className="pl-3 mt-2"><span className="font-semibold">External Audit Company:</span> McMillan Woods</p>
              <p className="pl-3 mt-2"><span className="font-semibold">Custodian:</span> Mauritius Commercial Bank (MCB), Global Prime Partners (GPP)</p>



            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
