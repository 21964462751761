import { CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function PreIpo() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Pre-IPO</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:gap-y-10">

                <div className="mt-5 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
                    <div className="lg:pr-4">
                        <div className="max-w-7xl text-base leading-7 text-stone-800">
                            <p className="mt-3 text-xl leading-8">
                            A ‘pre-IPO Investment’ is the purchase of a block of
shares in a private company in advance of its eventual
listing on a public exchange, which could take place in
1-3 years.
Altaï Group presents high-conviction, deeply evaluated opportunities to invest with a driven
leadership based on long-term cash-flow clarity, in
addition to the disruptive product/service.                            </p>
                            <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-0.5 font-semibold text-stone-800">
                            Late Stage
                            </span>
                            <p className="pl-3 mt-3">
                            Late-stage investing de-risks positions in high growth,
path-to-profitability companies and increases the
velocity of investor returns due to predictable IPO
times.
Price of shares purchased beyond Series D/E/F (late
stage) at pre-IPO, provides the potential to realize
higher returns upon a public listing.
                            </p>
                            <span className="mt-8 text-lg inline-flex items-center border-l-2 border-rose-500 px-2 py-O.5 font-semibold text-stone-800">
                            Purchase-Exit Lifecycle 
                            </span>
                            <ul role="list" className="mt-8 max-w-7xl text-stone-800 space-y-3">
                                    <li className="flex gap-x-3">
                                        <span className="inline-flex items-center rounded-full bg-rose-50 px-2 py-1 text-lg font-medium text-rose-700 ring-1 ring-inset ring-rose-200">
                                            1
                                        </span>
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Investors buy pre-IPO shares.</strong>Investors acquire interests in private companies, aiming to benefit from the growth potential before these companies become publicly traded. This early-stage investment allows them to purchase shares at a price that is generally lower than the anticipated public offering value, positioning them for potentially significant returns.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <span className="inline-flex items-center rounded-full bg-rose-50 px-2 py-1 text-lg font-medium text-rose-700 ring-1 ring-inset ring-rose-200">
                                            2
                                        </span>
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Restriction.</strong>The sale of these shares is typically subject to restrictions imposed by the company to control stock distribution and maintain ownership stability. These restrictions often include a right of first refusal for the company on resale or a lock-up period during which sales are prohibited, ensuring that early investments support the company's long-term growth.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <span className="inline-flex items-center rounded-full bg-rose-50 px-2 py-1 text-lg font-medium text-rose-700 ring-1 ring-inset ring-rose-200">
                                            3
                                        </span>
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">IPO.</strong>When the company decides to go public through an Initial Public Offering (IPO), it offers its shares to the general public for the first time. This transition from a private to a public entity allows the company to raise capital from a broader investor base and provides pre-IPO investors with an opportunity to realize gains on their early investments.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <span className="inline-flex items-center rounded-full bg-rose-50 px-2 py-1 text-lg font-medium text-rose-700 ring-1 ring-inset ring-rose-200">
                                            4
                                        </span>
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Locked-up.</strong>After the IPO, shares owned by insiders, including pre-IPO investors, are typically subject to a "lock-up" period, usually lasting six months. During this time, these shares cannot be sold, preventing a sudden flood of shares onto the market that could depress the stock price. This period also allows the market to stabilize and assess the company's value post-IPO.
                                        </span>
                                    </li>
                                    <li className="flex gap-x-3">
                                        <span className="inline-flex items-center rounded-full bg-rose-50 px-2 py-1 text-lg font-medium text-rose-700 ring-1 ring-inset ring-rose-200">
                                            5
                                        </span>
                                        <span className="text-justify">
                                            <strong className="font-semibold text-stone-900">Conclusion Insider.</strong>Following the lock-up period, a Special Purpose Vehicle (SPV) transfers the common shares to their respective owners. At this point, investors are free to sell their shares or maintain their position at their discretion. This marks the final phase of the purchase-exit lifecycle, where investors can fully realize their investment returns by selling their shares on the open market or continue to hold them in anticipation of further growth.
                                        </span>
                                    </li>
                                   
                                   
                                    
                                </ul>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
