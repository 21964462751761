import { CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'

export default function Equities() {
    return (
        <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
            <div className="absolute inset-0 -z-10 overflow-hidden">
                <svg
                    className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
                    aria-hidden="true"
                >
                    <defs>
                        <pattern
                            id="e813992c-7d03-4cc4-a2bd-151760b470a0"
                            width={200}
                            height={200}
                            x="50%"
                            y={-1}
                            patternUnits="userSpaceOnUse"
                        >
                            <path d="M100 200V.5M.5 .5H200" fill="none" />
                        </pattern>
                    </defs>
                    <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
                        <path
                            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
                            strokeWidth={0}
                        />
                    </svg>
                    <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
                </svg>
            </div>
            <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
                <div className="lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
                    <div>
                        <p className="text-base font-semibold leading-7 text-rose-600">Inovative Solutions</p>
                        <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Equities</h1>
                    </div>
                </div>
            </div>
            <div className="mx-auto max-w-2xl gap-x-8 g:mx-0 lg:max-w-none ">
                <div className=" ">
                    <p className="mt-3 text-xl leading-8">
                    <p className="mt-3 text-xl leading-8">
                                Structured products offer a versatile investment solution, adapting seamlessly to a variety of market conditions to enhance the risk-return profile of a portfolio. They also offer effective management of equity positions. With the capability to provide Mark to Market simulations and back-testing, investors can explore and evaluate the potential of any investment strategy, ensuring informed decision-making and optimized investment outcomes.
                            </p>
                            </p>
                    <ul role="list" className="space-x-0 lg:space-x-10 space-y-8 lg:space-y-0 lg:grid lg:grid-cols-3 mt-10 text-stone-600">
                        <li className="flex gap-x-3">
                            <GlobeAltIcon className="mt-1 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                            <span className="text-justify">
                                <strong className="font-semibold text-stone-900 ">Wide access to global markets.</strong> More than 3 million symbols across 87 markets
                                NYSE, NASDAQ, LSE, Euronext, HSE, etc.
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <RectangleGroupIcon className="mt-1 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                            <span className="text-justify">
                                <strong className="font-semibold text-stone-900">ETF and mutual funds.</strong> Сover most Exchange Traded Funds - ETFs - and wide range of mutual funds
                            </span>
                        </li>
                        <li className="flex gap-x-3">
                            <ComputerDesktopIcon className="mt-1 h-5 w-5 flex-none text-rose-600" aria-hidden="true" />
                            <span className="text-justify">
                                <strong className="font-semibold text-stone-900 ">Direct Market Access.</strong> Launch of Altaï Global’s electronic trading platform for Direct Market Access
                            </span>
                        </li>
                    </ul>
                </div>
                <div className="mt-10 mx-auto w-full max-w-7xl">
                    <div>
                        <div className="max-w-7xl text-base leading-7 text-stone-700 text-justify">
                            <p>
                               
</p>
  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
