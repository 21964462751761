import React from 'react'
import {
    Route,
    Routes,
  } from "react-router-dom";
  import Home from './landing/Home'
import Contact from './landing/Contact';
import Navbar from './landing/Navbar'
import Equities from './landing/Equities'
import FixedIncome from './landing/FixedIncome';
import Amc from './landing/Amc'
import StructuredProducts from './landing/StructuredProducts';
import PreIpo from './landing/PreIpo';
import FinancialEngineering from './landing/FinancialEngineering';
import RegulatoryInformation from './landing/RegulatoryInformation';
import Jurisdiction from './landing/Jurisdiction';
import Dashboard from './dashboard/Dashboard';

export default function App() {
  return (
    <>
    <Navbar/>
    <Routes>
        <Route path="/contact" element={<Contact/>} /> 
        <Route path="/products/structured-products" element={<StructuredProducts/>} />
        <Route path="/products/equities" element={<Equities/>} />
        <Route path="/products/fixed-income" element={<FixedIncome/>} />
        <Route path="/products/amc" element={<Amc/>} />
        <Route path="/products/pre-ipo" element={<PreIpo/>} />
        <Route path="/products/financial-engineering" element={<FinancialEngineering/>} />
        <Route path="/regulatory-information" element={<RegulatoryInformation/>} />
        <Route path="/jurisdiction" element={<Jurisdiction/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path="*" element={<Home/>} /> 
    </Routes>
    </>
  )
}
