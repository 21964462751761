import { Fragment, useState, useRef, useEffect } from 'react'
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react'

import { ChevronDownIcon, PhoneIcon, PlayCircleIcon, RectangleGroupIcon } from '@heroicons/react/20/solid'
import mountainLogo from './assets/mountains-logo.png';
import lottie from "lottie-web";
import {
  Bars3Icon,
  ChartPieIcon, XMarkIcon, ClipboardDocumentListIcon, GlobeAltIcon, ChartBarIcon, UserPlusIcon as ChatBubbleIcon, Cog8ToothIcon, AdjustmentsVerticalIcon, EnvelopeIcon, UserIcon, UsersIcon, CubeTransparentIcon
} from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom';

const products = [
  {
    href: '/products/equities',
    name: 'Equities',
    description:
      'Dive into the heart of global markets with our diverse equity offerings, tailored to empower your investment journey.',
    icon: GlobeAltIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    href: '/products/structured-products',
    name: 'Structured Products',
    description:
      'Elevate your portfolio with our innovative structured products, designed for risk-adjusted performance and strategic diversification.',
    icon: AdjustmentsVerticalIcon,
    pattern: {
      y: -6,
      squares: [
        [-1, 2],
        [1, 3],
      ],
    },
  },
  {
    href: '/products/amc',
    name: 'AMC',
    description:
      'Unveil our AMC offerings: Dynamic, actively managed certificates for flexible, professional portfolio management.',
    icon: CubeTransparentIcon,
  },
  {
    href: '/products/fixed-income',
    name: 'Fixed Income',
    description:
      'Investigate our fixed income opportunities: Stable, income-generating investments for a solid foundation in any portfolio.',
    icon: ChartBarIcon,

  },
  {
    href: '/products/pre-ipo',
    name: 'Pre-IPO',
    description:
      "Dive into our Pre-IPO ventures: Exclusive early-stage investment opportunities in tomorrow's market leaders.",
    icon: ChartPieIcon,

  },
  {
    href: '/products/financial-engineering',
    name: 'Financial Engineering',
    description:
      'Revolutionize your investment approach with our cutting-edge financial engineering, crafting custom solutions for complex financial challenges.',
    icon: Cog8ToothIcon,
  }
]
const callsToAction = [
  { name: 'Learn More', href: '#', icon: PlayCircleIcon },
  { name: 'Contact sales', href: '#', icon: PhoneIcon },
  { name: 'View all products', href: '#', icon: RectangleGroupIcon },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Navbar() {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const productsPopoverRef = useRef();
  const logocontainer = useRef(null);
  const animationInstance = useRef(null);
  useEffect(() => {
    if (animationInstance.current) {
      animationInstance.current.destroy(); // Adjust this line if necessary to correctly target your animation instance
    }
    animationInstance.current = lottie.loadAnimation({
      container: logocontainer.current, // the dom element that will contain the animation
      renderer: 'svg',
      loop: false,
      autoplay: true,
      animationData: require("./assets/logoanimation.json") // the path to the animation json
    });
    return () => {
      if (animationInstance.current) {
        animationInstance.current.destroy();
      }
    };
  }, [])
  return (
    <header className="relative isolate z-10 bg-white border-b border-stone-200">
      {/* <div className="absolute inset-0 -z-10 overflow-hidden" aria-hidden="true">
          <div className="absolute left-16 top-full -mt-16 transform-gpu opacity-50 blur-3xl xl:left-1/2 xl:-ml-80">
            <div
              className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#E11D48] to-[#404040]"
              style={{
                clipPath:
                  'polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)',
              }}
            />
          </div>
          <div className="absolute inset-x-0 bottom-0 h-px bg-stone-900/5" />
        </div> */}
      <div className="mx-auto flex max-w-7xl items-center justify-between py-2 px-6 lg:px-20">
        <div className="flex lg:flex-1">
          <Link to="/">
            <span className="sr-only">Altai Global</span>
            <div className="h-auto w-32" ref={logocontainer} id="animation-container"></div>
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-stone-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <Popover.Group className="hidden lg:flex lg:gap-x-12 ">
          <Popover>
            <Popover.Button ref={productsPopoverRef} className="flex items-center gap-x-1 text-sm font-semibold leading-6 text-stone-900">
              Products
              <ChevronDownIcon className="h-5 w-5 flex-none text-stone-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              <Popover.Panel className="absolute inset-x-0 top-0 -z-10 bg-white pt-14 shadow-lg ring-1 ring-stone-900/5">
                <div className="mx-auto grid max-w-7xl grid-cols-3 gap-x-4 px-6 py-10 lg:px-8 xl:gap-x-8">
                  {products.map((item) => (
                    <div key={item.name} onClick={() => productsPopoverRef.current?.click()} className="group relative p-6 text-sm leading-6 hover:bg-stone-50">
                      <div className="flex h-11 w-11 items-center justify-center  bg-stone-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-stone-600 group-hover:text-rose-600" aria-hidden="true" />
                      </div>
                      <Link to={item.href} className="mt-6 block font-semibold text-stone-900">
                        {item.name}
                        <span className="absolute inset-0" />
                      </Link>
                      <p className="mt-1 text-stone-600">{item.description}</p>
                    </div>
                  ))}
                </div>
                <div className="bg-stone-50">
                  <div className="mx-auto max-w-7xl px-6 lg:px-24">
                    <div className="grid grid-cols-3 divide-x divide-stone-900/5 border-x border-stone-900/5">
                      {/* {callsToAction.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-stone-900 hover:bg-stone-100"
                        >
                          <item.icon className="h-5 w-5 flex-none text-stone-400" aria-hidden="true" />
                          {item.name}
                        </a>
                      ))} */}
                    </div>
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>

          <p onClick={() => {
            navigate("/", { state: { targetId: "contact" } });
          }} className="text-sm font-semibold leading-6 text-stone-900 cursor-pointer">
            Contact Us
          </p>
          <p onClick={() => navigate("/regulatory-information")} className="text-sm font-semibold leading-6 text-stone-900 cursor-pointer">
            Regulatory
          </p>
          <p onClick={() => navigate("/jurisdiction")} className="text-sm font-semibold leading-6 text-stone-900 cursor-pointer">
            Jurisdiction
          </p>
        </Popover.Group>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end">
          <a href="https://clients.altai-global.com" className="text-sm font-semibold leading-6 text-gray-900">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>


        </div>
      </div>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-stone-900/10">
          <div className="flex items-center justify-between">
            <a href="#" className="-m-1.5 p-1.5">
              <span className="sr-only">Altai Global</span>
              <img
                className="h-8 w-auto"
                src={mountainLogo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-stone-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-stone-500/10">
              <div className="space-y-2 py-6">
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50">
                        Products
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...products, ...callsToAction].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-stone-900 hover:bg-stone-50"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <p onClick={() => {
                  navigate("/", { state: { targetId: "contact" } });
                }} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50"
                >
                  Contact Us
                </p>
                <p onClick={() => navigate("/regulatory-information")} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50">
                  Regulatory
                </p>
                <p onClick={() => navigate("/jurisdiction")} className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-stone-900 hover:bg-stone-50">
                  Jurisdiction
                </p>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
