

export default function Philosophy() {
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-full">
          <div className="relative overflow-hidden bg-stone-900 py-20 shadow-md sm:py-24">
            <img
              className="absolute inset-0 h-full w-full object-cover brightness-150 saturate-0"
              src="https://images.unsplash.com/photo-1573411765728-9e4b9073e2c5?q=80&w=3017&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
              alt=""
            />
            <div className="absolute inset-0 bg-stone-200/90 mix-blend-multiply" />
            <div className="absolute -left-80 -top-56 transform-gpu blur-3xl" aria-hidden="true">
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#fb7185] to-[#fb7185] opacity-[0.45]"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div
              className="hidden md:absolute md:bottom-16 md:left-[50rem] md:block md:transform-gpu md:blur-3xl"
              aria-hidden="true"
            >
              <div
                className="aspect-[1097/845] w-[68.5625rem] bg-gradient-to-r from-[#fb7185] to-[#e11d48] opacity-25"
                style={{
                  clipPath:
                    'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
                }}
              />
            </div>
            <div className="max-w-7xl relative mx-auto px-6 lg:px-20">
             
              <figure className="max-w-4xl">
                <blockquote className="mt-6 text-lg font-semibold text-stone-800 sm:text-xl sm:leading-8">
                  <p>
                    “At Altai Global, we prioritize relationships, guided by agility, ethics, and independence. Our approach is rooted in understanding and adapting to our clients' needs, ensuring integrity and trust guide our every action. As an independent entity, our focus remains unwaveringly on serving our clients' best interests, making our reputation our most cherished asset.”
                  </p>
                </blockquote>
                <figcaption className="mt-6 text-base text-stone-800">
                  <div className="font-semibold">Altai Management</div>
                  {/* <div className="mt-1">Partner</div> */}
                </figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>
    )
  }
