import { CloudArrowUpIcon, ComputerDesktopIcon, GlobeAltIcon, LockClosedIcon, RectangleGroupIcon, ServerIcon } from '@heroicons/react/20/solid'
const timeline = [
  {
    name: 'Mauritius Delisted',
    description:
      'Mauritius was taken off the FATF (the Financial Action Task Force) grey list. For more detailed information, please visit :',
    date: 'October 2021',
    dateTime: '2021-10',
    links: [
      { 
        title : "FATF's page on Mauritius",
        url: "https://www.fatf-gafi.org/en/countries/detail/Mauritius.html"
      }
    ]
  },
  {
    name: 'Commission Clearance',
    description:
      `On the 7th of January 2022, the European Commission removed Mauritius from its list of high-risk third countries. For more detailed information, please visit :`,
    date: 'Jan 2022',
    dateTime: '2022-01',
    links: [
      {
        title: "Mauritius Exits EU Risk List - Communique",
        url: "https://mcusercontent.com/4d5a383304f6766db2a144fa9/files/b9230670-0e7d-192b-748d-eccba2908dd4/Communique_Mauritius_Exits_from_the_EU_List_of_High_Risk_Third_Countries.docx.pdf"
      },
      {
        title: "EU Regulatory Update 2022",
        url: "https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=CELEX:32022R0229"
      }
    ]
  }
]
export default function Jurisdiction() {
  return (
    <div className="mx-auto relative isolate overflow-hidden bg-white px-6 py-8 lg:overflow-visible lg:px-24 max-w-7xl">
      <div className="absolute inset-0 -z-10 overflow-hidden">
        <svg
          className="absolute left-[max(50%,25rem)] top-0 h-[64rem] w-[128rem] -translate-x-1/2 stroke-stone-200 [mask-image:radial-gradient(64rem_64rem_at_top,white,transparent)]"
          aria-hidden="true"
        >
          <defs>
            <pattern
              id="e813992c-7d03-4cc4-a2bd-151760b470a0"
              width={200}
              height={200}
              x="50%"
              y={-1}
              patternUnits="userSpaceOnUse"
            >
              <path d="M100 200V.5M.5 .5H200" fill="none" />
            </pattern>
          </defs>
          <svg x="50%" y={-1} className="overflow-visible fill-stone-50">
            <path
              d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M-300.5 600h201v201h-201Z"
              strokeWidth={0}
            />
          </svg>
          <rect width="100%" height="100%" strokeWidth={0} fill="url(#e813992c-7d03-4cc4-a2bd-151760b470a0)" />
        </svg>
      </div>
      <div className="lg:max-w-none mx-auto grid grid-cols-2 items-start">
        <div className="w-full lg:col-span-2 lg:col-start-1 lg:row-start-1 lg:mx-auto lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8">
          <div>
            <p className="text-base font-semibold leading-7 text-rose-600">Compliance Insights</p>
            <h1 className="mt-2 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Jurisdiction</h1>
          </div>
        </div>
      </div>
      <div className="mx-auto grid max-w-7xl gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:gap-y-10">

        <div className="mt-5 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:gap-x-8">
          <div className="lg:pr-4">
            <div className="max-w-7xl text-base leading-7 text-stone-800">
              <p className="mt-3 text-xl leading-8">
                As an International Financial Center, Mauritius has established itself as a global and regional financial hub providing an attractive blend of reliability, efficiency, stability and transparency. Strategically located between Asia and Africa, and with a highly skilled labour force, Mauritius is a vibrant jurisdiction par excellence.
              </p>
              <p className="mt-3 text-xl leading-8">
                Since the enactment of the Mauritius Securities Act 2005 and the Securities Licencing Rules 2007, Mauritius has seen a significant surge in financial activity.
              </p>
              <p className="mt-3 text-xl leading-8">
                The jurisdiction’s client-centric responsiveness and FATF compliant regulatory and legislative frameworks have helped to streamline the licensing process and enhance competitiveness for global business operations.
              </p>

              <div className="bg-white mt-20">
                <div className="mx-auto max-w-7xl">
                  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-8 overflow-hidden lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    {timeline.map((item) => (
                      <div key={item.name}>
                        <time
                          dateTime={item.dateTime}
                          className="flex items-center text-sm font-semibold leading-6 text-rose-600"
                        >
                          <svg viewBox="0 0 4 4" className="mr-4 h-1 w-1 flex-none" aria-hidden="true">
                            <circle cx={2} cy={2} r={2} fill="currentColor" />
                          </svg>
                          {item.date}
                          <div
                            className="absolute -ml-2 h-px w-screen -translate-x-full bg-stone-900/10 sm:-ml-4 lg:static lg:-mr-6 lg:ml-8 lg:w-auto lg:flex-auto lg:translate-x-0"
                            aria-hidden="true"
                          />
                        </time>
                        <p className="mt-6 text-lg font-semibold leading-8 tracking-tight text-stone-900">{item.name}</p>
                        <p className="mt-1 text-base leading-7 text-stone-600">{item.description}</p>
                        {item.links.map(link => 
                          <a href={link.url} className="underline  flex hover:text-stone-500">
                            {link.title}
                          </a>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
