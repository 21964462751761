import React, { useState, useEffect } from 'react';
import { CloudArrowUpIcon, LockClosedIcon, ServerIcon } from '@heroicons/react/20/solid'
import AltaiBg from './assets/altai-bg.png'
const features = [
    {
        name: 'Push to deploy.',
        description:
            'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.',
        icon: CloudArrowUpIcon,
    },
    {
        name: 'SSL certificates.',
        description: 'Anim aute id magna aliqua ad ad non deserunt sunt. Qui irure qui lorem cupidatat commodo.',
        icon: LockClosedIcon,
    },
    {
        name: 'Database backups.',
        description: 'Ac tincidunt sapien vehicula erat auctor pellentesque rhoncus. Et magna sit morbi lobortis.',
        icon: ServerIcon,
    },
]

export default function Activities() {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        const position = window.pageYOffset;
        setScrollPosition(position * 0.1);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    return (
        <div className="py-16 relative overflow-hidden border-b border-stone-200 inner-shadow-bottom shadow-inner ">
            <img
                src={AltaiBg}
                alt=""
                className="opacity-20 absolute inset-0 -z-10"
                style={{
                    height: '110%', // Increase the height
                    width: '110%', // Increase the width
                    objectFit: 'cover', // Ensure the image covers the area
                    transform: `translateX(${scrollPosition}px)`, // Apply translation
                    left: '-5%', // Adjust positioning to center the image
                    top: '-5%', // Adjust positioning to ensure full coverage
                }}

            />
            <div className="mx-auto max-w-7xl lg:px-20 ">

                <div className="grid grid-cols-1 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
                    <div className="px-6 lg:px-0 lg:pr-4 lg:pt-4">
                        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg relative">
                            <h2 className="text-base font-semibold leading-7 text-rose-600">About Us</h2>
                            <p className="mt-1 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Our activities</p>
                            <p className="mt-6 text-lg leading-8 text-stone-700 ">
                            At Altai Global, we are dedicated to delivering exceptional financial services tailored to the unique needs of our clients. Our expertise spans a wide range of activities designed to empower your investment journey. <br/> <br/>Altaï Global is a regulated investment-dealer, supervised by the Mauritius FSC (Financial Services Commission).
                            </p>
                        </div>
                    </div>
                    <div className="px-6 sm:px-0 grid grid-cols-1 bg-transparent gap-3">
                        <div className="py-4">
                            <h1 className="border-l-2 border-rose-500 pl-4 text-lg font-bold tracking-tight text-stone-900">Intermediary</h1>
                            <p className="mt-3 text-lg leading-6 text-stone-700">Act as intermediary in the execuition of securities transaction for
                                clients</p>
                        </div>
                        <div className="py-4">
                            <h1 className="border-l-2 border-rose-500 pl-4 text-lg font-bold tracking-tight text-stone-900">Reselling</h1>
                            <p className="mt-3 text-lg leading-6 text-stone-700">Trade securities as principal with intention of reselling to the
                                public</p>
                        </div>
                        <div className="py-4">
                            <h1 className="border-l-2 border-rose-500 pl-4 text-lg font-bold tracking-tight text-stone-900">Advisory</h1>
                            <p className="mt-3 text-lg leading-6 text-stone-700">Give investment advice that is ancillary to the normal course of business
                                activities</p>

                        </div>
                        <div className="py-4">
                            <h1 className="border-l-2 border-rose-500 pl-4 text-lg font-bold tracking-tight text-stone-900">Portfolio Management</h1>
                            <p className="mt-3 text-lg leading-6 text-stone-700">Our service meticulously aligns client investments with their financial goals. </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
