import Hero from './Hero';
import About from './CoreProducts';
import CoreProducts from './CoreProducts';
import Activities from './Activities';
import Footer from './Footer'
import Offices from './Offices';
import Philosophy from './Philosophy';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

function Home() {
  const { state } = useLocation();
  const { targetId } = state || {};

  useEffect(() => {
    const el = document.getElementById(targetId);
    if (el) {
      el.scrollIntoView();
    }
  }, [targetId]);


  return (
    <div >
      <Hero/>
      <Activities/>
      <Philosophy/>
      <CoreProducts/>
      <Offices/>
      <Footer/>
    </div>
  );
}

export default Home;
