import { useId } from 'react'

import { motion, useMotionTemplate, useMotionValue } from 'framer-motion'
import { ClipboardDocumentListIcon, GlobeAltIcon, ChartBarIcon, UserPlusIcon as ChatBubbleIcon, Cog8ToothIcon, AdjustmentsVerticalIcon, EnvelopeIcon, UserIcon, UsersIcon, CubeTransparentIcon, ChartPieIcon } from '@heroicons/react/20/solid'
import { Link, useNavigate } from 'react-router-dom'

const resources = [
  {
    href: '/products/equities',
    name: 'Equities',
    description:
      'Dive into the heart of global markets with our diverse equity offerings, tailored to empower your investment journey.',
    icon: GlobeAltIcon,
    pattern: {
      y: 16,
      squares: [
        [0, 1],
        [1, 3],
      ],
    },
  },
  {
    href: '/products/structured-products',
    name: 'Structured Products',
    description:
      'Elevate your portfolio with our innovative structured products, designed for risk-adjusted performance and strategic diversification.',
    icon: AdjustmentsVerticalIcon,
    pattern: {
      y: -6,
      squares: [
        [-1, 2],
        [1, 3],
      ],
    },
  },
  {
    href: '/products/amc',
    name: 'AMC',
    description:
      'Unveil our AMC offerings: Dynamic, actively managed certificates for flexible, professional portfolio management.',
    icon: CubeTransparentIcon,
    pattern: {
      y: 32,
      squares: [
        [0, 2],
        [1, 4],
      ],
    },
  },
  {
    href: '/products/fixed-income',
    name: 'Fixed Income',
    description:
      'Investigate our fixed income opportunities: Stable, income-generating investments for a solid foundation in any portfolio.',     
    icon: ChartBarIcon,
    pattern: {
      y: 22,
      squares: [[0, 1]],
    },
    
  },
  {
    href: '/products/pre-ipo',
    name: 'Pre-IPO',
    description:
      "Dive into our Pre-IPO ventures: Exclusive early-stage investment opportunities in tomorrow's market leaders.",     
    icon: ChartPieIcon,
    pattern: {
      y: 22,
      squares: [[0, 1]],
    },
    
  },
  {
    href: '/products/financial-engineering',
    name: 'Financial Engineering',
    description:
      'Revolutionize your investment approach with our cutting-edge financial engineering, crafting custom solutions for complex financial challenges.',     
    icon: Cog8ToothIcon,
    pattern: {
      y: 22,
      squares: [[0, 1]],
    },
  }
]

function ResourceIcon({ icon: Icon }) {
  return (
    <div className="flex h-10 w-10 items-center justify-center rounded-full bg-stone-900/5 ring-1 ring-stone-900/25 backdrop-blur-[2px] transition duration-300 group-hover:bg-white/50 group-hover:ring-stone-900/25">
      <Icon className="h-7 w-7 fill-stone-700/10 stroke-stone-700 transition-colors duration-300 group-hover:stroke-stone-900 " />
    </div>
  )
}

function ResourcePattern({ mouseX, mouseY, ...gridProps }) {
  let maskImage = useMotionTemplate`radial-gradient(180px at ${mouseX}px ${mouseY}px, white, transparent)`
  let style = { maskImage, WebkitMaskImage: maskImage }

  return (
    <div className="pointer-events-none">
      <div className="absolute inset-0 transition duration-300 [mask-image:linear-gradient(white,transparent)] group-hover:opacity-50">
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/[0.02] stroke-black/5"
          {...gridProps}
        />
      </div>
      <motion.div
        className="absolute inset-0 bg-gradient-to-r from-[#fecdd3] to-[#f5f5f4] opacity-0 transition duration-300 group-hover:opacity-100 "
        style={style}
      />
      <motion.div
        className="absolute inset-0 opacity-0 mix-blend-overlay transition duration-300 group-hover:opacity-100"
        style={style}
      >
        <GridPattern
          width={72}
          height={56}
          x="50%"
          className="absolute inset-x-0 inset-y-[-30%] h-[160%] w-full skew-y-[-18deg] fill-black/50 stroke-black/70"
          {...gridProps}
        />
      </motion.div>
    </div>
  )
}

function Resource({ resource }) {
  const navigate = useNavigate();

  let mouseX = useMotionValue(0)
  let mouseY = useMotionValue(0)

  function onMouseMove({ currentTarget, clientX, clientY }) {
    let { left, top } = currentTarget.getBoundingClientRect()
    mouseX.set(clientX - left)
    mouseY.set(clientY - top)
  }

  return (
    <div
      key={resource.href}
      onClick={() => navigate(resource.href)}
      onMouseMove={onMouseMove}
      className="group relative flex bg-stone-50 transition-shadow hover:shadow-md hover:shadow-stone-900/5 "
    >
      <ResourcePattern {...resource.pattern} mouseX={mouseX} mouseY={mouseY} />
      <div className="absolute inset-0 ring-1 ring-inset ring-stone-300 group-hover:ring-stone-400" />
      <div className="relative px-4 pb-20 pt-16">
        <ResourceIcon icon={resource.icon} />
        <h3 className="mt-4 text-xl font-semibold leading-7 text-stone-900">
  
            {resource.name}

        </h3>
        <p className="mt-1 mb-3 text-lg leading-6  text-stone-600">
          {resource.description}
        </p>
        <Link to={resource.href} className=" text-md font-semibold leading-6 text-stone-600">
            Learn more <span aria-hidden="true">→</span>
          </Link>
      </div>
    </div>
  )
}

export default function CoreProducts() {
  return (
    <div className="mx-auto max-w-7xl px-6 lg:px-20 relative pt-16 ">
        <div className="absolute inset-x-0 t-0 -z-10 flex -translate-y-1/2 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
            <svg className="h-[40rem] w-[80rem] flex-none stroke-stone-200" aria-hidden="true">
              <defs>
                <pattern
                  id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                  width={200}
                  height={200}
                  x="50%"
                  y="50%"
                  patternUnits="userSpaceOnUse"
                  patternTransform="translate(-100 0)"
                >
                  <path d="M.5 200V.5H200" fill="none" />
                </pattern>
              </defs>
              <svg x="50%" y="50%" className="overflow-visible fill-stone-50">
                <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
              </svg>
              <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
            </svg>
          </div>
      <h2 class="text-center text-base font-semibold leading-7 text-rose-600">Innovative Solutions</h2>
     <h2 className="text-center mt-1 text-3xl font-bold tracking-tight text-stone-900 sm:text-4xl">Core Products</h2>
      <div className="not-prose mt-4 grid grid-cols-1 gap-8 pt-10 sm:grid-cols-3 ">
        {resources.map((resource) => (
          <Resource key={resource.href} resource={resource} />
        ))}
      </div>
    </div>
  )
}

function GridPattern({ width, height, x, y, squares, ...props }) {
  let patternId = useId()

  return (
    <svg aria-hidden="true" {...props}>
      <defs>
        <pattern
          id={patternId}
          width={width}
          height={height}
          patternUnits="userSpaceOnUse"
          x={x}
          y={y}
        >
          <path d={`M.5 ${height}V.5H${width}`} fill="none" />
        </pattern>
      </defs>
      <rect
        width="100%"
        height="100%"
        strokeWidth={0}
        fill={`url(#${patternId})`}
      />
      {squares && (
        <svg x={x} y={y} className="overflow-visible">
          {squares.map(([x, y]) => (
            <rect
              strokeWidth="0"
              key={`${x}-${y}`}
              width={width + 1}
              height={height + 1}
              x={x * width}
              y={y * height}
            />
          ))}
        </svg>
      )}
    </svg>
  )
}

